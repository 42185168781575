<template>
  <div class="content-wrapper">
    <div class="view-content">
      <div
        ref="materials-selection-content"
        class="wrapper">
        <div class="main-content materials-selection pb-5">
          <div
            v-if="loaded"
            class="columns-wrapper">
            <div class="column column-left">
              <div
                v-if="data.doorsCountOption && data.doorsCountOption.number"
                class="form-group mb-5">
                <div class="door-no-selection flex flex-column">
                  <c-form-radio
                    :disabled="true"
                    :withImage="true"
                    :value="data.doorsCountOption.number"
                    class="mr-0">
                    <span>{{data.doorsCountOption.name}}</span>
                    <img
                      class="door-count-img"
                      :src="require('@/assets/images/door-count/' + data.doorsCountOption.number + '-doors.png')"
                      :alt="'Antal Skydedøre - ' + data.doorsCountOption.number" />
                  </c-form-radio>
                </div>
              </div>
            </div>

            <div class="column column-right">
              <validation-observer
                v-if="data.doorsCountOption && data.doorsCountOption.number"
                class="wardrobe-layout with-materials mt-4 ml-auto mr-auto"
                :data-doors-count="data.doorsCountOption.number"
                tag="div"
                ref="materials-form"
                v-slot="{ invalid }">
                <div
                  :class="{
                    'wardrobe-front':true,
                    'is-invalid': invalid && validationTriggered
                    }"
                  v-bind:style="{ 'aspect-ratio': (data.width / data.height) }">
                  <div
                    v-for="(door, index) in data.doorsCountOption.number"
                    :key="'door-' + index + '-' + updateCounter"
                    class="wardrobe-door">

                    <validation-provider
                      v-for="n in data.selectedDivisions[index]"
                      :key="'door-' + index + '-part-' + n + '-' + data.doorsSettings[index].doorFillings[n - 1].fillingID"
                      rules="required"
                      :name="'door-' + index + '-part-' + n"
                      v-slot="{ errors }"
                      class="door-part-validation">
                      <input
                        v-model="data.doorsSettings[index].doorFillings[n - 1].fillingID"
                        :key="data.doorsSettings[index].doorFillings[n - 1].fillingID"/>
                      <div
                        :key="'door-' + index + '-part-' + n + '-display'"
                        :class="{
                          'door-part': true,
                          'is-invalid': errors.length
                        }"
                        @click.prevent="emitOpenColorSelectionPopup(index, n - 1)"
                        :style="{
                          'backgroundImage': data.doorsSettings[index].doorFillings[n - 1].fillingBg || '',
                          'opacity': data.doorsSettings[index].doorFillings[n - 1].fillingOpacity ? '.95' : '1' }">
                          <span
                            v-if="!data.doorsSettings[index].doorFillings[n - 1].fillingBg"
                            class="wardrobe-dimesions wardrobe-door-width">
                            Vælg materiale
                          </span>
                      </div>
                    </validation-provider>
                  </div>
                  <div>
                    <div class="wardrobe-side wardobe-side-left wardobe-core"></div>
                    <validation-provider
                      v-if="leftSideType"
                      rules="required"
                      :name="'door-side-left'"
                      v-slot="{ errors }"
                      class="door-side-validation"
                      :key="'door-left-side-validation-' + data.doorsSettings[leftSideType].fillingID">
                      <div
                        :key="'door-left-side-' + data.doorsSettings[leftSideType].fillingID"
                        :class="{
                          'wardrobe-side wardobe-side-left': true,
                          'is-impact': leftSideType === 'impact',
                          'is-gable': leftSideType === 'gable',
                          'is-invalid': errors.length
                        }"
                        @click.prevent="emitOpenColorSelectionPopup(null, null, leftSideType)"
                        v-bind:style="{ 'backgroundImage': leftSideType === 'impact' ? impactFillingBg : gableFillingBg }">
                      </div>
                      <input v-model="data.doorsSettings[leftSideType].fillingID"/>
                    </validation-provider>

                    <div class="wardrobe-side wardobe-side-right wardobe-core"></div>
                    <validation-provider
                      v-if="rightSideType"
                      rules="required"
                      :name="'door-side-right'"
                      v-slot="{ errors }"
                      class="door-side-validation"
                      :key="'door-right-side-validation-' + data.doorsSettings[rightSideType].fillingID">
                      <div
                        :key="'door-right-side-' + data.doorsSettings[rightSideType].fillingID"
                        :class="{
                          'wardrobe-side wardobe-side-right': true,
                          'is-impact': rightSideType === 'impact',
                          'is-gable': rightSideType === 'gable',
                          'is-invalid': errors.length
                        }"
                        @click.prevent="emitOpenColorSelectionPopup(null, null, rightSideType)"
                        v-bind:style="{ 'backgroundImage': rightSideType === 'impact' ? impactFillingBg : gableFillingBg }">
                      </div>
                      <input v-model="data.doorsSettings[rightSideType].fillingID"/>
                    </validation-provider>
                  </div>
                  <div
                    v-if="validationTriggered && invalid"
                    class="alert alert-danger alert-small">
                    Vælg venligst materiale til alle tilgængelige overflader.
                  </div>
                </div>
              </validation-observer>

              <div
                class="door-division-buttons flex"
                :data-doors-count="data.doorsCountOption.number">
                <c-button
                  v-for="(door, index) in data.doorsCountOption.number"
                  :key="index"
                  :is-small="true"
                  @click.prevent="emitOpenDoorDivisionPopup(index, data.doorsSettings[index].doorDivision)">
                  Vælg døre-deling
                </c-button>
              </div>
            </div>
          </div>
          <wardrobe-summary
            v-if="loaded"
            :current-doors-settings="data.doorsSettings" />
        </div>
      </div>
    </div>
    <div class="wrapper-narrow">
      <div class="buttons-bar">
        <c-button
          :theme="'secondary'"
          class="mr-auto"
          @click.prevent="submitForm('prev')">
          Tilbage
        </c-button>

        <c-button
          class="ml-auto"
          @click.prevent="validateForm">
          Videre
        </c-button>
      </div>
    </div>
    <door-division-selection-popup
      v-if="loaded"
      :doorDivisionOptions="doorDivisionOptions" />
    <color-selection-popup
      v-if="loaded"
      :availableFillings="{
        door: doorFillingsOptions,
        gable: gableFillingsOptions,
        impact: impactFillingsOptions
      }" />
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import CustomDecimals from '@/utils/CustomDecimals.js';
import ColorSelectionPopup from '@/components/popups/ColorSelectionPopup.vue';
import DoorDivisionSelectionPopup from '@/components/popups/DoorDivisionSelectionPopup.vue';
import html2canvas from 'html2canvas';

export default {
  name: 'materials-selection',
  components: {
    'color-selection-popup': ColorSelectionPopup,
    'door-division-selection-popup': DoorDivisionSelectionPopup
  },
  computed: {
    wardrobePaddingTop () {
      return `calc(${this.data.height} / ${this.data.width} * 100%)`;
    },
    leftSideType () {
      if (!this.wardrobePositions || !this.data.wardrobePosition) {
        return '';
      }

      let currentPosition = this.wardrobePositions.find(item => item.id === this.data.wardrobePosition);

      if (!currentPosition) {
        return '';
      }

      if (this.data.useImpact && currentPosition.impact_position && currentPosition.impact_position.indexOf('left') > -1) {
        return 'impact';
      }

      if (this.data.gable && currentPosition.gable_position && currentPosition.gable_position.indexOf('left') > -1) {
        return 'gable';
      }

      return '';
    },
    rightSideType () {
      if (!this.wardrobePositions || !this.data.wardrobePosition) {
        return '';
      }

      let currentPosition = this.wardrobePositions.find(item => item.id === this.data.wardrobePosition);

      if (!currentPosition) {
        return '';
      }

      if (this.data.useImpact && currentPosition.impact_position && currentPosition.impact_position.indexOf('right') > -1) {
        return 'impact';
      }

      if (this.data.gable && currentPosition.gable_position && currentPosition.gable_position.indexOf('right') > -1) {
        return 'gable';
      }

      return '';
    }
  },
  data () {
    return {
      data: {
        doorsCountOption: null,
        gable: null,
        height: null,
        selectedDivisions: [],
        useImpact: false,
        doorsSettings: {},
        wardrobePosition: null,
        width: null,
        doorsSoftClosePostions: []
      },
      doorDivisionOptions: [],
      doorFillingsOptions: [],
      gableFillingBg: null,
      gableFillingsOptions: [],
      gableOptions: [],
      impactFillingBg: null,
      impactFillingsOptions: [],
      wardrobePositions: [],
      loaded: false,
      validationTriggered: false,
      updateCounter: 0
    }
  },
  mounted () {
    this.$bus.$on('update-door-filling', this.updateDoorFilling);
    this.$bus.$on('update-door-division', this.updateDoorDivision);

    this.data.doorsCountOption = this.$store.getters['getWardrobeDoorsCountOption'];
    this.data.gable = this.$store.getters['getWardrobeGable'];
    this.data.height = this.$store.getters['getWardrobeHeight'];
    this.data.useImpact = this.$store.getters['getWardrobeUseImpact'];
    this.data.wardrobePosition = this.$store.getters['getWardrobePosition'];
    this.data.width = this.$store.getters['getWardrobeWidth'];
    this.data.doorsSettings = JSON.parse(JSON.stringify(this.$store.getters['getWardrobeDoorsSettings']));

    Object.keys(this.data.doorsSettings).forEach(key => {
      if (key !== 'gable' && key !== 'impact' && key >= this.data.doorsCountOption.number) {
        delete this.data.doorsSettings[key];
      } else if (key === 'gable' && !this.data.gable) {
        delete this.data.doorsSettings[key];
      } else if (key === 'impact' && !this.data.useImpact) {
        delete this.data.doorsSettings[key];
      }
    });

    this.data.doorsSoftClosePostions = this.$store.getters['getWardrobeDoorsSoftClosePostions'];

    this.wardrobePositions = this.$store.getters['getDataWardrobePositions'];
    this.gableOptions = this.$store.getters['getDataGableOptions'];
    this.gableFillingsOptions = this.$store.getters['getDataGableFillings'];
    this.doorFillingsOptions = this.$store.getters['getDataDoorFillings'];
    this.impactFillingsOptions = this.$store.getters['getDataImpactFillings'];
    this.doorDivisionOptions = this.$store.getters['getDataDoorDivisions'];

    let smallestDivideNumber = this.doorDivisionOptions.reduce((prev, curr) => prev.divide < curr.divide ? prev : curr);

    for (let i = 0; i < this.data.doorsCountOption.number; i++) {
      if (!this.data.doorsSettings[i]) {
        this.data.doorsSettings[i] = {};
      }

      if (this.data.doorsSettings[i].doorDivision && this.data.doorsSettings[i].doorDivision.divide && (this.doorDivisionOptions.find(item => item.divide === this.data.doorsSettings[i].doorDivision.divide))) {
        this.data.selectedDivisions.push(this.data.doorsSettings[i].doorDivision.divide);
      } else {
        this.data.doorsSettings[i]['doorDivision'] = smallestDivideNumber;
        this.data.selectedDivisions.push(smallestDivideNumber.divide);
      }

      if (('doorFillings' in this.data.doorsSettings[i]) && this.data.doorsSettings[i].doorDivision.divide) {
        let doorDivisionsCount = this.data.doorsSettings[i].doorDivision.divide;
        for (let j = 0; j < doorDivisionsCount; j++) {
          let fillingObj = {};
          if (this.data.doorsSettings[i].doorFillings[j] && this.data.doorsSettings[i].doorFillings[j].fillingID && this.doorFillingsOptions.find(item => item.id === this.data.doorsSettings[i].doorFillings[j].fillingID)) {
            fillingObj = this.getFillingObjByID(this.data.doorsSettings[i].doorFillings[j].fillingID);
            this.data.doorsSettings[i].doorFillings[j] = fillingObj;
          } else {
            fillingObj = this.getCleanDoorFillingObject(smallestDivideNumber.divide);
            this.data.doorsSettings[i].doorFillings[j] = fillingObj;
          }
        }
      } else {
        this.data.doorsSettings[i].doorFillings = {};
        let newDoorFillingsObject = this.getCleanDoorFillingObject(smallestDivideNumber.divide);
        this.data.doorsSettings[i].doorFillings = newDoorFillingsObject;
      }
    }

    if (this.data.gable) {
      if (this.data.doorsSettings.gable && this.data.doorsSettings.gable.fillingID && (this.gableFillingsOptions.find(item => item.id === this.data.doorsSettings.gable.fillingID))) {
        this.data.doorsSettings.gable = this.getFillingObjByID(this.data.doorsSettings.gable.fillingID, 'gable');
      } else {
        this.data.doorsSettings.gable = {
          fillingID: null,
          fillingBg: null
        }
      }
    }

    if (this.data.useImpact) {
      if (this.data.doorsSettings.impact && this.data.doorsSettings.impact.fillingID && (this.gableFillingsOptions.find(item => item.id === this.data.doorsSettings.impact.fillingID))) {
        this.data.doorsSettings.impact = this.getFillingObjByID(this.data.doorsSettings.impact.fillingID, 'impact');
      } else {
        this.data.doorsSettings.impact = {
          fillingID: null,
          fillingBg: null
        }
      }
    }

    if (this.data.doorsSettings.gable) {
      Vue.set(this, 'gableFillingBg', this.data.doorsSettings.gable.fillingBg);
    }

    if (this.data.doorsSettings.impact) {
      Vue.set(this, 'impactFillingBg', this.data.doorsSettings.impact.fillingBg);
    }

    this.loaded = true;
  },
  methods: {
    validateForm () {
      this.validationTriggered = true;
      FormUtils.validate(this.$refs['materials-form'], this.submitForm, this.scrollToTop, 'next');
    },
    scrollToTop () {
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    },
    async submitForm (param) {
      let doorsSettingsCopy = JSON.parse(JSON.stringify(this.data.doorsSettings));
      let doorsSettingsCopyKeys = Object.keys(doorsSettingsCopy);
      for (let i = 0; i < doorsSettingsCopyKeys.length; i++) {
        if (doorsSettingsCopyKeys[i] === 'impact' || doorsSettingsCopyKeys[i] === 'gable') {
          delete doorsSettingsCopy[doorsSettingsCopyKeys[i]].fillingBg;
          delete doorsSettingsCopy[doorsSettingsCopyKeys[i]].fillingOpacity;
        } else {
          for (let j = 0; j < doorsSettingsCopy[doorsSettingsCopyKeys[i]].doorDivision.divide; j++) {
            delete doorsSettingsCopy[doorsSettingsCopyKeys[i]].doorFillings[j].fillingBg;
            delete doorsSettingsCopy[doorsSettingsCopyKeys[i]].doorFillings[j].fillingOpacity;
          }
        }
      }
      this.$store.commit('setWardrobeDoorsSettings', doorsSettingsCopy);
      if (param === 'prev') {
        this.$router.push('/number-of-doors');
      } else {
        await this.saveCanvasImg();
        this.$router.push('/inventory');
      }
    },
    updateDoorFilling (doorNumber, doorPart, selectedFilling, sideType, isMirror = false) {
      let fillingObj = this.getFillingObjByID(selectedFilling, sideType);

      Vue.nextTick(() => {
        if (sideType === 'gable') {
          Vue.set(this.data.doorsSettings, 'gable', fillingObj);
          Vue.set(this, 'gableFillingBg', fillingObj.fillingBg);
        } else if (sideType === 'impact') {
          Vue.set(this.data.doorsSettings, 'impact', fillingObj);
          Vue.set(this, 'impactFillingBg', fillingObj.fillingBg);
        } else {
          let doorDivisionsCount = this.data.doorsSettings[doorNumber].doorDivision.divide;

          let prevFillingID = this.data.doorsSettings[doorNumber].doorFillings[doorPart].fillingID;

          let prevFillingMaterial = null;
          if (prevFillingID) {
            prevFillingMaterial = this.getFillingMaterial(prevFillingID, sideType);
          }

          if (isMirror && doorDivisionsCount > 1) {
            for (let i = 0; i < doorDivisionsCount; i++) {
              Vue.set(this.data.doorsSettings[doorNumber].doorFillings, i, fillingObj);
            }
          } else if (!isMirror && doorDivisionsCount > 1 && prevFillingMaterial && prevFillingMaterial.is_mirror) {
            for (let i = 0; i < doorDivisionsCount; i++) {
              if (i === doorPart) {
                Vue.set(this.data.doorsSettings[doorNumber].doorFillings, doorPart, fillingObj);
              } else {
                Vue.set(
                  this.data.doorsSettings[doorNumber].doorFillings,
                  i,
                  {
                    fillingBg: null,
                    fillingID: null,
                    fillingOpacity: false
                  }
                );
              }
            }
          } else {
            Vue.set(this.data.doorsSettings[doorNumber].doorFillings, doorPart, fillingObj);
          }
        }
      });

      Vue.set(this, 'updateCounter', CustomDecimals.generateUUID());

      this.updatePrice();
    },
    getFillingObjByID (selectedFillingID, sideType = '') {
      return {
        fillingID: selectedFillingID,
        fillingBg: this.getFillingStyle(selectedFillingID, sideType),
        fillingOpacity: sideType.length ? null : this.getFillingOPacity(selectedFillingID),
      }
    },
    updateDoorDivision (doorIndex, newDivision) {
      Vue.set(this.data.selectedDivisions, doorIndex, newDivision.divide);
      Vue.set(this.data.doorsSettings[doorIndex], 'doorDivision', newDivision);
      let newDoorFillingsObject = this.getCleanDoorFillingObject(newDivision.divide);
      Vue.set(this.data.doorsSettings[doorIndex], 'doorFillings', newDoorFillingsObject);

      this.updatePrice();
    },
    getCleanDoorFillingObject (doorDivisionsNumber) {
      let newDoorFillingsObject = {}
      for (let i = 0; i < doorDivisionsNumber; i++) {
        newDoorFillingsObject[i] = {
          fillingBg: null,
          fillingID: null,
          fillingOpacity: false
        }
      }

      return newDoorFillingsObject;
    },
    getFillingStyle(selectedFillingID, sideType) {
      if (!selectedFillingID) {
        return '';
      }

      let selectedFilling = null;

      if (sideType === 'gable') {
        selectedFilling = this.gableFillingsOptions.find(item => item.id === selectedFillingID);
      } else if (sideType === 'impact') {
        selectedFilling = this.impactFillingsOptions.find(item => item.id === selectedFillingID);
      } else {
        selectedFilling = this.doorFillingsOptions.find(item => item.id === selectedFillingID);
      }

      if (!selectedFilling) {
        return '';
      }

      return 'url(' + selectedFilling.background + ')'
    },
    getFillingMaterial(selectedFillingID, sideType) {
      if (!selectedFillingID) {
        return '';
      }

      let selectedFilling = null;

      if (sideType === 'gable') {
        selectedFilling = this.gableFillingsOptions.find(item => item.id === selectedFillingID);
      } else if (sideType === 'impact') {
        selectedFilling = this.impactFillingsOptions.find(item => item.id === selectedFillingID);
      } else {
        selectedFilling = this.doorFillingsOptions.find(item => item.id === selectedFillingID);
      }

      return selectedFilling;
    },
    getFillingOPacity (selectedFillingID) {
      if (!selectedFillingID) {
        return false;
      }

      let selectedFilling = this.doorFillingsOptions.find(item => item.id === selectedFillingID);

      if (!selectedFilling) {
        return false;
      }

      return selectedFilling.is_mirror;
    },
    getDoorPartLabel (doorPartIndex, allPartsCount) {
      if (allPartsCount === 1) {
        return '';
      }

      if (doorPartIndex === 0) {
        return 'Top';
      }

      if (doorPartIndex === allPartsCount - 1) {
        return 'Bund'
      }

      return 'Midter'
    },
    emitOpenColorSelectionPopup (doorIndex, doorPartIndex, sideType = '') {
      if (doorIndex === null && doorPartIndex === null && !sideType.length) {
        return;
      }
      let currentDoorPartLabel = null;
      if (!sideType.length) {
        currentDoorPartLabel = this.getDoorPartLabel(doorPartIndex, this.data.doorsSettings[doorIndex].doorDivision.divide);
      }

      let currentFillingID = this.getCurrentFillingID(doorIndex, doorPartIndex, sideType);
      this.$bus.$emit('color-selection-popup-show', doorIndex, doorPartIndex, currentDoorPartLabel, currentFillingID, sideType);
    },
    emitOpenDoorDivisionPopup (doorIndex) {
      this.$bus.$emit('door-division-selection-popup-show', doorIndex, this.data.doorsSettings[doorIndex].doorDivision);
    },
    getCurrentFillingID(doorIndex, doorPartIndex, sideType = '') {
      if (sideType.length && this.data.doorsSettings[sideType] && this.data.doorsSettings[sideType].fillingID) {
        return this.data.doorsSettings[sideType].fillingID;
      } else if (this.data.doorsSettings[doorIndex] && this.data.doorsSettings[doorIndex].doorFillings && this.data.doorsSettings[doorIndex].doorFillings[doorPartIndex]) {
        return this.data.doorsSettings[doorIndex].doorFillings[doorPartIndex].fillingID;
      }

      return null;
    },
    updatePrice () {
      this.$bus.$emit('update-wardrobe-price');
    },
    async saveCanvasImg () {
      try {
        let materialsContent = this.$refs['materials-selection-content'];
        let materialsContentCanvas = await html2canvas(materialsContent);
        window.pandoraCanvasScreenshotMaterials = materialsContentCanvas.toDataURL();
      } catch (error) {
        console.error('Exporting image failed:', error);
      }
    },
  },
  beforeDestroy () {
    this.$bus.$off('update-door-filling', this.updateDoorFilling);
    this.$bus.$off('update-door-division', this.updateDoorDivision);
  }
};
</script>
