<template>
  <div class="content-wrapper">
    <div class="view-content">
      <div class="wrapper">
        <div class="main-content place-order pb-5">
          <div class="card full-width relative">
            <h2 class="card-heading">Bestil Garderobe</h2>
            <div class="card-body">
              <validation-observer
                ref="place-order-form"
                tag="form"
                class="columns-wrapper">

                <div class="column column-left">

                  <h2 class="mt-1">Faktureringsadresse</h2>

                  <div class="form-group">
                    <validation-provider
                      name="name"
                      rules="required"
                      class="full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="name"
                        class="order-form-label">
                        <span class="input-name">Navn</span>
                        <c-input
                          type="text"
                          v-model="data.name"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="email"
                      rules="required|email"
                      class="full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="email"
                        class="order-form-label">
                        <span class="input-name">Email</span>
                        <c-input
                          type="text"
                          v-model="data.email"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  <!-- </div>

                  <div class="form-group"> -->
                    <validation-provider
                      name="phone"
                      rules="required"
                      class="ml-2 ml-md-0 full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="phone"
                        class="order-form-label">
                        <span class="input-name">Telefon</span>
                        <c-input
                          type="text"
                          v-model="data.phone"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="address-1"
                      rules="required"
                      class="full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="address-1"
                        class="order-form-label">
                        <span class="input-name">Adresse</span>
                        <c-input
                          type="text"
                          v-model="data.address_1"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  <!-- </div>

                  <div class="form-group"> -->
                    <validation-provider
                      name="address-2"
                      rules=""
                      class="ml-2 ml-md-0 full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="address-2"
                        class="order-form-label">
                        <span class="input-name">Adresse fortsat</span>
                        <c-input
                          type="text"
                          v-model="data.address_2"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="postcode"
                      rules="required"
                      class="full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="postcode"
                        class="order-form-label">
                        <span class="input-name">Postnr</span>
                        <c-input
                          type="text"
                          v-model="data.postcode"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  <!-- </div>

                  <div class="form-group"> -->
                    <validation-provider
                      name="city"
                      rules="required"
                      class="ml-2 ml-md-0 full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="city"
                        class="order-form-label">
                        <span class="input-name">By</span>
                        <c-input
                          type="text"
                          v-model="data.city"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="country"
                      rules="required"
                      class="full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="country"
                        class="order-form-label">
                        <span class="input-name">Land</span>
                        <c-input
                          type="text"
                          v-model="data.country"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="same_delivery-address"
                      rules="required"
                      class="mt-2"
                      v-slot="{ errors }">

                      <c-checkbox
                        :is-small="true"
                        v-model="data.same_delivery_address"
                        :state="errors.length ? 'invalid' : null">
                        Samme adresse som leveringsadressen
                      </c-checkbox>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="terms-and-conditions"
                      rules="required|is-checked"
                      v-slot="{ errors }">

                      <c-checkbox
                        :is-small="true"
                        v-model="terms_and_conditions"
                        :state="errors.length ? 'invalid' : null">
                        Jeg har læst og accepterer <a href="documents/terms_and_conditions.pdf" target="_blank" rel="nofollow noopener noreferrer">handelsbetingelserne</a>
                      </c-checkbox>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>
                </div>

                <div class="column column-right">
                  <h2 class="mt-1">Leveringsadresse</h2>

                  <div class="form-group">
                    <validation-provider
                      name="delivery-name"
                      :rules="data.same_delivery_address ? '' : 'required'"
                      class="full-width"
                      v-slot="{ errors }">
                      <label
                        for="delivery-name"
                        :class="{
                          'order-form-label': true,
                          'is-disabled': data.same_delivery_address
                        }">
                        <span class="input-name">Navn</span>
                        <c-input
                          type="text"
                          v-model="data.delivery_name"
                          :disabled="data.same_delivery_address"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="delivery-email"
                      :rules="data.same_delivery_address ? '' : 'required|email'"
                      class="full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="delivery-email"
                        :class="{
                          'order-form-label': true,
                          'is-disabled': data.same_delivery_address
                        }">
                        <span class="input-name">Email</span>
                        <c-input
                          type="text"
                          v-model="data.delivery_email"
                          :disabled="data.same_delivery_address"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  <!-- </div>

                  <div class="form-group"> -->
                    <validation-provider
                      name="delivery-phone"
                      :rules="data.same_delivery_address ? '' : 'required'"
                      class="ml-2 ml-md-0 full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="delivery-phone"
                        :class="{
                          'order-form-label': true,
                          'is-disabled': data.same_delivery_address
                        }">
                        <span class="input-name">Telefon</span>
                        <c-input
                          type="text"
                          v-model="data.delivery_phone"
                          :disabled="data.same_delivery_address"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="delivery-address-1"
                      :rules="data.same_delivery_address ? '' : 'required'"
                      class="full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="delivery-address-1"
                        :class="{
                          'order-form-label': true,
                          'is-disabled': data.same_delivery_address
                        }">
                        <span class="input-name">Adresse</span>
                        <c-input
                          type="text"
                          v-model="data.delivery_address_1"
                          :disabled="data.same_delivery_address"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  <!-- </div>

                  <div class="form-group"> -->
                    <validation-provider
                      name="delivery-address-2"
                      rules=""
                      class="ml-2 ml-md-0 full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="delivery-address-2"
                        :class="{
                          'order-form-label': true,
                          'is-disabled': data.same_delivery_address
                        }">
                        <span class="input-name">Adresse fortsat</span>
                        <c-input
                          type="text"
                          v-model="data.delivery_address_2"
                          :disabled="data.same_delivery_address"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="delivery-postcode"
                      :rules="data.same_delivery_address ? '' : 'required'"
                      class="full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="delivery-postcode"
                        :class="{
                          'order-form-label': true,
                          'is-disabled': data.same_delivery_address
                        }">
                        <span class="input-name">Postnr</span>
                        <c-input
                          type="text"
                          v-model="data.delivery_postcode"
                          :disabled="data.same_delivery_address"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  <!-- </div>

                  <div class="form-group"> -->
                    <validation-provider
                      name="delivery-city"
                      :rules="data.same_delivery_address ? '' : 'required'"
                      class="ml-2 ml-md-0 full-width order-form-field"
                      v-slot="{ errors }">
                      <label
                        for="delivery-city"
                        :class="{
                          'order-form-label': true,
                          'is-disabled': data.same_delivery_address
                        }">
                        <span class="input-name">By</span>
                        <c-input
                          type="text"
                          v-model="data.delivery_city"
                          :disabled="data.same_delivery_address"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>

                  <div class="form-group">
                    <validation-provider
                      name="delivery-country"
                      :rules="data.same_delivery_address ? '' : 'required'"
                      class="full-width"
                      v-slot="{ errors }">
                      <label
                        for="delivery-country"
                        :class="{
                          'order-form-label': true,
                          'is-disabled': data.same_delivery_address
                        }">
                        <span class="input-name">Land</span>
                        <c-input
                          type="text"
                          v-model="data.delivery_country"
                          :disabled="data.same_delivery_address"
                          :state="errors.length ? 'invalid' : null"/>
                      </label>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>
                </div>

              </validation-observer>
            </div>
            <template v-if="submitInProgress">
              <div class="loading-overlay">
                <div class="loader">
                  <span>Databehandling er i gang&hellip;</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-narrow">
      <div class="buttons-bar">
        <c-button
          :theme="'secondary'"
          class="mr-auto"
          @click.prevent="$router.push('/order-summary')">
          Tilbage
        </c-button>
        <c-button
          :theme="'primary'"
          class="ml-auto"
          @click.prevent="validateForm">
          Bestil
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'place-order',
  data () {
    return {
      data: {
        name: '',
        email: '',
        phone: '',
        address_1: '',
        address_2: '',
        postcode: '',
        city: '',
        country: '',
        same_delivery_address: false,
        delivery_name: '',
        delivery_email: '',
        delivery_phone: '',
        delivery_address_1: '',
        delivery_address_2: '',
        delivery_postcode: '',
        delivery_city: '',
        delivery_country: ''
      },
      terms_and_conditions: false,
      height: 0,
      width: 0,
      gable: null,
      useImpact: false,
      wardrobePosition: null,
      leftEndAdjustment: false,
      leftStrokeAdjustment: false,
      rightEndAdjustment: false,
      rightStrokeAdjustment: false,
      doorsCountOption: null,
      doorsSettings: null,
      wardrobePrice: 0,
      submitInProgress: false
    }
  },
  mounted () {
    this.height = this.$store.getters['getWardrobeHeight'] || 0;
    this.width = this.$store.getters['getWardrobeWidth'] || 0;
    this.wardrobePosition = this.$store.getters['getWardrobePosition'];
    this.gable = this.$store.getters['getWardrobeGable'];
    this.useImpact = this.$store.getters['getWardrobeUseImpact'];
    this.leftEndAdjustment = this.$store.getters['getWardrobeLeftEndAdjustment'] || false;
    this.leftStrokeAdjustment = this.$store.getters['getWardrobeLeftStrokeAdjustment'];
    this.rightEndAdjustment = this.$store.getters['getWardrobeRightEndAdjustment'];
    this.rightStrokeAdjustment = this.$store.getters['getWardrobeRightStrokeAdjustment'];

    this.doorsCountOption = this.$store.getters['getWardrobeDoorsCountOption'];
    this.doorsSettings = JSON.parse(JSON.stringify(this.$store.getters['getWardrobeDoorsSettings']));

    this.wardrobePrice = this.$store.getters['getWardrobePrice'];
  },
  methods: {
    validateForm () {
      FormUtils.validate(this.$refs['place-order-form'], this.submitForm, this.scrollToInvalid);
    },
    scrollToInvalid () {
      this.$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    },
    submitForm () {
      this.submitInProgress = true;
      let formDataToSend = this.prepareFormDataToSend();

      FormUtils.submitInBackground(this, {
        endpoint: '/api/order/store',
        params: formDataToSend,
        successAction: (response) => {
          this.submitInProgress = false;
          if (response.payment && response.payment_url) {
            window.open(response.payment_url, '_self');
          } else {
            Vue.swal({
              title: 'Der opstod en fejl',
              html: 'Omdirigering til betalingsside mislykkedes. Prøv igen.',
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
          }
        },
        failAction: () => {
          this.submitInProgress = false;
        }
      }, 'file');
    },
    prepareFormDataToSend () {
      let gableFillingId = 0;
      if (this.doorsSettings.gable && this.doorsSettings.gable.fillingID) {
        gableFillingId = this.doorsSettings.gable.fillingID;
      }
      let impactFillingId = 0;
      if (this.doorsSettings.impact && this.doorsSettings.impact.fillingID) {
        impactFillingId = this.doorsSettings.impact.fillingID;
      }

      let wardrobeParam = {
        height: this.height,
        gable_id: this.gable || 0,
        gable_filling_id: gableFillingId,
        impact: this.useImpact,
        impact_filling_id: impactFillingId,
        left_end_adjustment: this.leftEndAdjustment,
        left_stroke_adjustment: this.leftStrokeAdjustment,
        right_end_adjustment: this.rightEndAdjustment,
        right_stroke_adjustment: this.rightStrokeAdjustment,
        position_id: this.wardrobePosition,
        width: this.width,
        screenshot: window.pandoraCanvasScreenshotFrontView,
        screenshot_bottom: window.pandoraCanvasScreenshotTopView,
        screenshot_doors: window.pandoraCanvasScreenshotMaterials,
        is_walk_in_closet: this.$store.getters['getIsWalkInCloset']
      }

      let doorsParam = {
        number: this.doorsCountOption.number,
        items: []
      };

      for (let i = 0; i < this.doorsCountOption.number; i++) {
        let fillingsArr = [];
        let doorDivisionID = this.doorsSettings[i].doorDivision.id;
        for (let j = 0; j < this.doorsSettings[i].doorDivision.divide; j++) {
          let fillingId = 0;
          if (this.doorsSettings[i] && this.doorsSettings[i].doorFillings[j] && this.doorsSettings[i].doorFillings[j].fillingID) {
            fillingId = this.doorsSettings[i].doorFillings[j].fillingID
          }
          fillingsArr.push({
            filling_id: fillingId
          });
        }

        doorsParam.items.push({
          division_id: doorDivisionID,
          fillings: fillingsArr,
          soft_close: this.doorsSettings[i].hasSoftClose
        });
      }

      let inventoryParam = this.$store.getters['getWardrobeInventoryItemsWithPositions'];

      return {
        wardrobe: wardrobeParam,
        doors: doorsParam,
        inventory: inventoryParam,
        price: this.wardrobePrice,
        name: this.data.name,
        email: this.data.email,
        phone: this.data.phone,
        address_1: this.data.address_1,
        address_2: this.data.address_2,
        postcode: this.data.postcode,
        city: this.data.city,
        country: this.data.country,
        same_delivery_address: this.data.same_delivery_address,
        delivery_name: this.data.same_delivery_address ? this.data.name : this.data.delivery_name,
        delivery_email: this.data.same_delivery_address ? this.data.email : this.data.delivery_email,
        delivery_phone: this.data.same_delivery_address ? this.data.phone : this.data.delivery_phone,
        delivery_address_1: this.data.same_delivery_address ? this.data.address_1 : this.data.delivery_address_1,
        delivery_address_2: this.data.same_delivery_address ? this.data.address_2 : this.data.delivery_address_2,
        delivery_postcode: this.data.same_delivery_address ? this.data.postcode : this.data.delivery_postcode,
        delivery_city: this.data.same_delivery_address ? this.data.city : this.data.delivery_city,
        delivery_country: this.data.same_delivery_address ? this.data.country : this.data.delivery_country
      }
    }
  }
}
</script>
